<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			name: 'rating_actual',
			form: {
				ratingEngine: false,
				data: null
			},
			ratingEngineLoading: false,
			errorRating: false,
		}
	},
	computed: {
		...mapGetters({
			_ratingByClient: 'arceEeff/ratingByClient',
		})
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			let points = 100 / fields.length
			let fill = 0
			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}

			if(!Object.keys(data).length) {
				this.$set(this, 'form', {})
				return 
			}

			this.$set(this, 'form', data)
		},
		save() {
			let vm = this
			let payload =  {
				client:  this.client,
				form: this.name,
				data: Object.assign({...this.form}),
				fill: vm.calcFill()
			}
			vm._setDataChild(payload)
			vm.$emit('updateArce')
		},

		toggleTableChildren(event) {
			let $target = event.target
			let $parent = $target.closest('tr')
			let $table = $parent.closest('table')
			let parentId = $parent.dataset.id
			let $children = $table.querySelectorAll(`[data-parent='${parentId}']`)
			$parent.classList.toggle('view')
			$children.forEach($child => {
				$child.classList.toggle('active')
			})
		},

		runRatingEngine() {
			let vm = this
			this.errorRating = false
			vm.showModal('#ratingEngineModal')
			vm.ratingEngineLoading = true
			setTimeout(() => {
				let canCalcRating = vm.client.arce.hasOwnProperty('analisis_riesgo_financiero') 
				
				if(canCalcRating) {
					canCalcRating = vm.client.arce['analisis_riesgo_financiero'].eeff
					if(canCalcRating) {
						vm.form.data = vm._ratingByClient(vm.client.id)
						vm.form.ratingEngine = true
						vm.save()
					} else {
						vm.errorRating = true
						vm.$notify({
							type: 'warn',
							group: 'simulator',
							title: 'Debes selecionar los Estados Finacieros',
							text: 'Antes de cargar el Rating debes selecionar los Estados financieros en la ficha Analisis de Riesgo Financiero'
						})
					}	
				} else {
					vm.errorRating = true
						vm.$notify({
							type: 'warn',
							group: 'simulator',
							title: 'Debes selecionar los Estados Finacieros',
							text: 'Antes de cargar el Rating debes selecionar los Estados financieros en la ficha Analisis de Riesgo Financiero'
						})
				}
				vm.ratingEngineLoading = false
				
			}, 3000)
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		closeModal() {
			this.hiddenSteps()
			this.showSteps('stepsRating', 1)
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		})
	},

	mounted() {
		$('#ratingEngineModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#ratingEngineModal').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<div class="tab-content">
		<div id="FromCurrentRating" class="tab-pane fade show active" >
			<div class="text-right py-2">
				<button class="s-btn s-btn--primary" @click="showModal('#formRatingModal')">
					Editar
				</button>
			</div>
			<div class="">
				<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_fr1" aria-expanded="true" aria-controls="c1_fr1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Actual</span></h6>
					</div>
				</div>
				<div id="c1_fr1" class="collapse show" aria-labelledby="headingOne" data-v-step="srf2">
					<div v-if="form.ratingEngine" class="d-flex flex-wrap">
						<div class="col-4 py-2">
							<label class="d-block m-0">
								<p class="m-0">Escala</p>
								<input class="form-control form-control--disabled" disabled type="text" value="Escala Larga BBVA">
							</label>
						</div>
						<div class="col-8 py-2">
							
						</div>
						<div class="col-4 py-2">
							<label class="d-block m-0">
								<p class="m-0">Modelo de Rating</p>
								<input class="form-control form-control--disabled" disabled type="text" value="">
							</label>
						</div>
						<div class="col-4 py-2">
							<label class="d-block m-0">
								<p class="m-0">Año de EEFF</p>
								<input class="form-control form-control--disabled" disabled type="text" value="2019-12-31">
							</label>
						</div>
					</div>
					<div class="py-3">
						<button class="s-btn s-btn--primary" @click="runRatingEngine" data-v-step="srf1">Llamar Motor de Rating</button>
					</div>
				</div>
				<div v-if="form.ratingEngine">
					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_fr2" aria-expanded="true" aria-controls="c1_fr2">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Independiente</span></h6>
						</div>
					</div>
					<div id="c1_fr2" class="collapse hide" aria-labelledby="headingOne">
						<div v-if="form.ratingEngine && form.data"  class="d-flex flex-wrap">
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Puntuación Total del Rating</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.total">
								</label>
							</div>
							
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Valor de Rating Largo</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.escalaMaestra">
								</label>
							</div>
							<div class="col-4 py-2">
								
							</div>
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Bloque Extra</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.totales.clienteR">
								</label>
							</div>
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Bloque Cuantitativo</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.totales.cuantitativoR">
								</label>
							</div>
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Bloque Comportamiento</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.totales.buroR">
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_fr3" aria-expanded="true" aria-controls="c1_fr3">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Overlay</span></h6>
						</div>
					</div>
					<div id="c1_fr3" class="collapse hide" aria-labelledby="headingOne">
						<div v-if="form.ratingEngine" class="d-flex flex-wrap">
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Total ajustado puntaje de rating</p>
									<input class="form-control form-control--disabled" disabled type="text" value="0">
								</label>
							</div>
							
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Valor de Rating Largo ajustado</p>
									<input class="form-control form-control--disabled" disabled type="text" value="">
								</label>
							</div>
						</div>
					</div>
					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_fr4" aria-expanded="true" aria-controls="c1_fr4">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Final</span></h6>
						</div>
					</div>
					<div id="c1_fr4" class="collapse hide" aria-labelledby="headingOne">
						<div v-if="form.ratingEngine" class="d-flex flex-wrap">
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Número de puntaje</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.total">
								</label>
							</div>
							
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Valor Rating Largo</p>
									<input class="form-control form-control--disabled" disabled type="text" :value="form.data.escalaMaestra">
								</label>
							</div>
							<div class="col-4 py-2">
								<label class="d-block m-0">
									<p class="m-0">Probabilidad de Incumplimiento</p>
									<input class="form-control form-control--disabled" disabled type="text" value="7.00 %">
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="FromStudioRating" class="tab-pane fade py-3">
			<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_fre1" aria-expanded="true" aria-controls="c1_fre1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Estudio</span></h6>
				</div>
			</div>
			<div id="c1_fre1" class="collapse show" aria-labelledby="headingOne">
				<table class="w-100 s-table">
					<thead>
						<tr>
							<th>Fecha de EEFF</th>
							<th>MESES DEL EJERCICIO</th>
							<th>ESTADO DEL EJERCICIO</th>
							<th>CERTIFICACIÓN</th>
							<th>OPINIÓN DE AUDITOR</th>
							<th>AJUSTE</th>
							<th>TIPO DE EEFF</th>
							<th>RATING CORTO</th>
							<th>RATING LARGO</th>
							<th>CÓDIGO DE USUARIO</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>12/31/2019</td>
							<td>12</td>
							<td>Calculated</td>
							<td>Audiado</td>
							<td></td>
							<td>No ajustado</td>
							<td>Individual</td>
							<td>BBB-1</td>
							<td>BBB-1</td>
							<td>XSDSDD123x</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="s-collapse__header mt-4" data-toggle="collapse" data-target="#c1_fre4" aria-expanded="true" aria-controls="c1_fre4">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Modelo de rating</span></h6>
				</div>
			</div>
			<div id="c1_fre4" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Fecha de Estado Financiero</p>
							<input class="form-control form-control--disabled" disabled type="text" value="2019-12-31">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Periodo</p>
							<input class="form-control form-control--disabled" disabled type="text" value="12">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Tipo de certificación</p>
							<input class="form-control form-control--disabled" disabled type="text" value="Auditado">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Tipo de ajuste</p>
							<input class="form-control form-control--disabled" disabled type="text" value="No ajustado">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Tipo de estado financiero</p>
							<input class="form-control form-control--disabled" disabled type="text" value="Individual">
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header mt-4" data-toggle="collapse" data-target="#c1_fre2" aria-expanded="true" aria-controls="c1_fre2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Independiente</span></h6>
				</div>
			</div>
			<div id="c1_fre2" class="collapse" aria-labelledby="headingOne">
				<div class="py-3" v-if="form.data">
					<table class="w-100 s-table">
						<thead>
							<tr>
								<th></th>
								<th>Descripción</th>
								<th>Valor</th>
								<th>Puntuación</th>
								<th>Maximo puntaje</th>
							</tr>
						</thead>
						<tbody>
							<tr data-id="cuantitativoR">
								<td></td>
								<td>
									<div class="position-relative">
										<button class="s-table-dropdown" @click="toggleTableChildren">
											<SvgIcon name="chevronright"/>
										</button>
									BLOQUE CUANTITATIVO
									</div>
								</td>
								<td>0</td>
								<td>{{form.data.totales.cuantitativoR}}</td>
								<td>30</td>
							</tr>
							<tr v-for="(item, index) in form.data.cuantitativoR" class="child" data-parent="cuantitativoR" :key="`cuantitativoR-${index}`">
								<td></td>
								<td class="pl-3">{{item.campo}}</td>
								<td>{{item.valor}}</td>
								<td>{{item.puntaje}}</td>
								<td>{{item.max}}</td>
							</tr>
							<tr data-id="buroR">
								<td></td>
								<td>
									<div class="position-relative">
										<button class="s-table-dropdown" @click="toggleTableChildren">
											<SvgIcon name="chevronright"/>
										</button>
									BLOQUE COMPORTAMENTAL
									</div>
								</td>
								<td>0</td>
								<td>{{form.data.totales.buroR}}</td>
								<td>50</td>
							</tr>
							<tr v-for="(item, index) in form.data.buroR" class="child" data-parent="buroR" :key="`buroR-${index}`">
								<td></td>
								<td class="pl-3">{{item.campo}}</td>
								<td>{{item.valor}}</td>
								<td>{{item.puntaje}}</td>
								<td>{{item.max}}</td>
							</tr>
							<tr data-id="clienteR">
								<td></td>
								<td>
									<div class="position-relative">
										<button class="s-table-dropdown" @click="toggleTableChildren">
											<SvgIcon name="chevronright"/>
										</button>
									BLOQUE CLIENTE
									</div>
								</td>
								<td>0</td>
								<td>{{form.data.totales.clienteR}}</td>
								<td>50</td>
							</tr>
							<tr v-for="(item, index) in form.data.clienteR" class="child" data-parent="clienteR" :key="`clienteR-${index}`">
								<td></td>
								<td class="pl-3">{{item.campo}}</td>
								<td>{{item.valor}}</td>
								<td>{{item.puntaje}}</td>
								<td>{{item.max}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="s-collapse__header mt-4" data-toggle="collapse" data-target="#c1_fre5" aria-expanded="true" aria-controls="c1_fre5">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Overlay</span></h6>
				</div>
			</div>
			<div id="c1_fre5" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Número de puntaje ajustado</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Valor de Rating largo ajusado</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header mt-4" data-toggle="collapse" data-target="#c1_fre6" aria-expanded="true" aria-controls="c1_fre6">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Override</span></h6>
				</div>
			</div>
			<div id="c1_fre6" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap" v-if="form.data">
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Número de puntaje</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Valor de Rating largo</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="form.data.escalaMaestra">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Valor del rating ajustado</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header mt-4" data-toggle="collapse" data-target="#c1_fre7" aria-expanded="true" aria-controls="c1_fre7">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Final</span></h6>
				</div>
			</div>
			<div id="c1_fre7" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap" v-if="form.data">
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Número de puntaje</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="form.data.total">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Valor de Rating largo</p>
							<input class="form-control form-control--disabled" disabled type="text" :value="form.data.escalaMaestra">
						</label>
					</div>
					<div class="col-4 py-2">
						<label class="d-block m-0">
							<p class="m-0">Probabilidad de Incumplimiento</p>
							<input class="form-control form-control--disabled" disabled type="text" value="7.00 %">
						</label>
					</div>
				</div>
			</div>
		</div>

		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formRatingModal" tabindex="-1" role="dialog" aria-labelledby="formRatingModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="">
						<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_frm1" aria-expanded="true" aria-controls="c1_frm1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Actual</span></h6>
							</div>
						</div>
						<div id="c1_frm1" class="collapse show" aria-labelledby="headingOne" data-v-step="srf2">
							<div v-if="form.ratingEngine" class="d-flex flex-wrap">
								<div class="col-4 py-2">
									<label class="d-block m-0">
										<p class="m-0">Escala</p>
										<input class="form-control form-control--disabled" disabled type="text" value="Escala Larga BBVA">
									</label>
								</div>
								<div class="col-8 py-2">
									
								</div>
								<div class="col-4 py-2">
									<label class="d-block m-0">
										<p class="m-0">Modelo de Rating</p>
										<input class="form-control form-control--disabled" disabled type="text" value="">
									</label>
								</div>
								<div class="col-4 py-2">
									<label class="d-block m-0">
										<p class="m-0">Año de EEFF</p>
										<input class="form-control form-control--disabled" disabled type="text" value="2019-12-31">
									</label>
								</div>
							</div>
							<div class="py-3">
								<button class="s-btn s-btn--primary" @click="runRatingEngine" data-v-step="srf1">Llamar Motor de Rating</button>
							</div>
						</div>
						<div v-if="form.ratingEngine">
							<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_frm2" aria-expanded="true" aria-controls="c1_frm2">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Independiente</span></h6>
								</div>
							</div>
							<div id="c1_frm2" class="collapse hide" aria-labelledby="headingOne">
								<div v-if="form.ratingEngine" class="d-flex flex-wrap">
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Puntuación Total del Rating</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.total">
										</label>
									</div>
									
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Valor de Rating Largo</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.escalaMaestra">
										</label>
									</div>
									<div class="col-4 py-2">
										
									</div>
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Bloque Extra</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.totales.clienteR">
										</label>
									</div>
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Bloque Cuantitativo</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.totales.cuantitativoR">
										</label>
									</div>
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Bloque Comportamiento</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.totales.buroR">
										</label>
									</div>
								</div>
							</div>
							<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_frm3" aria-expanded="true" aria-controls="c1_frm3">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Overlay</span></h6>
								</div>
							</div>
							<div id="c1_frm3" class="collapse hide" aria-labelledby="headingOne">
								<div v-if="form.ratingEngine" class="d-flex flex-wrap">
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Total ajustado puntaje de rating</p>
											<input class="form-control form-control--disabled" disabled type="text" value="0">
										</label>
									</div>
									
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Valor de Rating Largo ajustado</p>
											<input class="form-control form-control--disabled" disabled type="text" value="">
										</label>
									</div>
								</div>
							</div>
							<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_frm4" aria-expanded="true" aria-controls="c1_frm4">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Rating Final</span></h6>
								</div>
							</div>
							<div id="c1_frm4" class="collapse hide" aria-labelledby="headingOne">
								<div v-if="form.ratingEngine" class="d-flex flex-wrap">
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Número de puntaje</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.total">
										</label>
									</div>
									
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Valor Rating Largo</p>
											<input class="form-control form-control--disabled" disabled type="text" :value="form.data.escalaMaestra">
										</label>
									</div>
									<div class="col-4 py-2">
										<label class="d-block m-0">
											<p class="m-0">Probabilidad de Incumplimiento</p>
											<input class="form-control form-control--disabled" disabled type="text" value="7.00 %">
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>
		<!-- ================ -->
		<!-- ================ -->
		<!-- ================ -->
			<div class="modal s-modal fade" id="ratingEngineModal" tabindex="-1" role="dialog" aria-labelledby="ratingEngineModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title w-100 text-center" id="ratingEngineModalLabel">Rating Engine</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="py-5">
						<div class="col-12 text-center" v-if="!ratingEngineLoading">
							<div v-if="form.ratingEngine">
								<div class="text-success" style="font-size: 3em">
									<svg-icon name="check "></svg-icon>
								</div>
								<p class="font-weight-bold">Rating Calculado exitosamente:</p>
								<p>Éxitoso. Ahora puedes cerrar esta ventana</p>
							</div>
							<div v-else-if="errorRating">
								<div class="text-warning" style="font-size: 3em">
									<svg-icon name="warning "></svg-icon>
								</div>
								<p class="font-weight-bold">Error al calcular Rating:</p>
								<p>Compueba datos cargados en ficha Riesgo Financiero</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="ratingEngineLoading" class="s-modal__loader">
					<img class="m-auto gray" src="@/assets/simulator/loader.gif" alt="">
				</div>
				</div>
			</div>
		</div>

	</div>
</template>
<style lang="scss" scoped>
.s-table{
	&-dropdown{
		position: absolute;
		right: 100%;
		background-color: transparent;
		width: 1rem;
		height: 1rem;
		padding: 0;
		text-align: center;
		line-height: 1rem;
		border: 0;
		outline: none;
		transition: 0.3s;
	}
	tr.child{
		display: none;
		&.active{
			display: table-row;
		}
	}
	tr.view{
		.s-table{
			&-dropdown{
				transform: rotate(90deg);
			}
		}
	}
}
</style>
